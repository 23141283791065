import React from "react";
import { Link } from "react-router-dom";
import { Hero, H1, Box, Grid } from "./Home.styled";
import Helmet from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Premium Icons and Illustrations</title>
      </Helmet>
      <Hero>
        <H1>Premium and free design resources for Creator</H1>
        <p>
          Thousands of unique icons in 4 styles (color, duotone, outline and
          fill/solid).
        </p>
        <Link to="/icons">Browse Icons</Link>
      </Hero>
      <Grid>
        <Box>
          <h1>All icons are drawn on a pixel-based grid</h1>
          <p>
            Universal Icon Set is drawn on a pixel-based grid of 24px x 24px and
            scaled-up linearly to different sizes. The grid contains 2px padding
            for the surrounding live area.
          </p>
        </Box>
        <Box style={{ background: "#d5f7ff" }}>
          <h1>Consistent design principles</h1>
          <p>
            Icons are designed based on same concept. Shapes are bold and
            geometric. They have a consistent look, ensuring readability and
            clarity, even at small sizes.
          </p>
        </Box>
        <Box style={{ background: "#ffd5f8" }}>
          <h1>Choose the style that suits you best</h1>
          <p>
            You can easily change the icons style just in one click. Feel free
            to try different icons options for your project.
          </p>
        </Box>
        <Box style={{ background: "#feffcd" }}>
          <h1>Change the color of the details</h1>
          <p>
            Experience even more flexibility with SVGBook icons. Change the
            color of the icon or just its individual elements.
          </p>
        </Box>
        <Box style={{ background: "#e0ffd5" }}>
          <h1>Get all file formats</h1>
          <p>
            The icons have been exported in different formats. This will allow
            you to work even faster, regardless of the program in which you are
            working.
          </p>
        </Box>
        <Box style={{ background: "#d7d5ff" }}>
          <h1>HOME Page is here</h1>
        </Box>
      </Grid>
    </>
  );
};

export default Home;
