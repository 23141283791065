import * as Icons from "@svgbook/react-icons";

const IconsList = [
  {
    name: "Activity",
    tags: ["Activity", "heartbeat"],
    component: Icons["Activity"],
  },
  {
    name: "Copy",
    tags: ["Copy", "clipboard"],
    component: Icons["Copy"],
  },
  {
    name: "Circle",
    tags: ["Circle", "shape"],
    component: Icons["Circle"],
  },
  {
    name: "Download",
    tags: ["download"],
    component: Icons["Download"],
  },
  {
    name: "ArrowsRight",
    tags: ["arrows", "right", "forward"],
    component: Icons["ArrowsRight"],
  },
];

const IconKeys = Object.keys(Icons);

IconsList.forEach((item) => {
  let idx = IconKeys.indexOf(item.name);
  if (idx !== -1) {
    IconKeys.splice(idx, 1);
  }
});

IconKeys.forEach((item) => {
  IconsList.push({
    name: item,
    tags: [],
    component: Icons[item],
  });
});

export default IconsList;
