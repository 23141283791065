import React, { useRef } from "react";
import styled from "styled-components";
import * as Icons from "@svgbook/react-icons";
import { useRecoilValue } from "recoil";
import { saveAs } from "file-saver";
import { Svg2Png } from "svg2png-converter";

import {
  colorAtom,
  designAtom,
  iconSizeAtom,
  strokeSizeAtom,
  selectedIconAtom,
} from "../state/atoms";

const DownloadBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  border-left: 1px solid #c5c5c5;
  overflow-y: scroll;
  padding: 15px;
`;
const Downloader = () => {
  const design = useRecoilValue(designAtom);
  const color = useRecoilValue(colorAtom);
  const size = useRecoilValue(iconSizeAtom);
  const strokeSize = useRecoilValue(strokeSizeAtom);
  const selectedIcon = useRecoilValue(selectedIconAtom);

  const IconRef = useRef();
  const IconPreview = Icons[selectedIcon];

  const _handleDownloadSVG = () => {
    if (!IconRef.current?.outerHTML) return;
    const blob = new Blob([IconRef.current.outerHTML]);
    saveAs(blob, `${selectedIcon}.svg`);
  };

  const _handleDownloadPNG = () => {
    if (!IconRef.current?.outerHTML) return;
    Svg2Png.save(IconRef.current, `${selectedIcon}.png`, {
      scaleX: 2.667,
      scaleY: 2.667,
    });
  };

  return (
    <DownloadBar>
      {selectedIcon ? (
        <>
          <div className="downloadHead">
            <h2>
              <IconPreview
                ref={IconRef}
                design={design}
                color={color}
                size={size}
                strokeWidth={strokeSize}
              />
              {selectedIcon}
            </h2>
          </div>
          <div className="form-field">
            <label htmlFor="reactCode">
              Icon Code <span>(for ReactJS)</span>
            </label>
            <textarea name="reactCode" id="">
              {/* TODO: Output Reactjs Code */}
            </textarea>
          </div>
          <div className="form-field">
            <label htmlFor="reactCode">SVG Code</label>
            <textarea name="reactCode" id="">
              {/* TODO: Output SVG Code */}
            </textarea>
          </div>
          <div className="btnGroup downloadFooter">
            <button className="btn" onClick={_handleDownloadSVG}>
              <Icons.Download strokeWidth={2} size="18" color="#fff" />
              SVG
            </button>
            <button onClick={_handleDownloadPNG}>
              <Icons.Download strokeWidth={2} size="18" color="#fff" />
              PNG
            </button>
          </div>
        </>
      ) : (
        <div>
          <h1>
            Search your best fit Icons and simply click and download your
            selected icons.{" "}
          </h1>
        </div>
      )}
    </DownloadBar>
  );
};

export default Downloader;
