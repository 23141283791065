import React, { Fragment, useState } from "react";
import { ChromePicker } from "react-color";
import { useRecoilState } from "recoil";
import * as Icons from "@svgbook/react-icons";
import {
  colorAtom,
  secondaryColorAtom,
  designAtom,
  iconSizeAtom,
  strokeSizeAtom,
  frameDesignAtom,
  frameColorAtom,
  frameBorderColorAtom,
  frameBorderRadiusAtom,
} from "../state/atoms";

function Filter(props) {
  const [icoPrimary, setIcoPrimary] = useState(false);
  const [icoSecondary, setIcoSecondary] = useState(false);
  const [icoFrameborder, setIcoFrameborder] = useState(false);
  const [icoFrameBackground, setIcoFrameBackground] = useState(false);

  const availableDesigns = [
    {
      name: "outline",
      icon: () => <Icons.Circle size={24} color={"#673BB7"} />,
    },
    {
      name: "fill",
      icon: () => <Icons.Circle size={24} color={"#673BB7"} design="fill" />,
    },
    {
      name: "duotone",
      icon: () => <Icons.Circle size={24} color={"#673BB7"} design="duotone" />,
    },
    {
      name: "color",
      icon: () => <Icons.Circle size={24} color={"#673BB7"} design="color" />,
    },
  ];
  const availableIconSizes = [18, 24, 36, 48, 72, 96];
  const strokes = { light: 0.5, regular: 1, bold: 1.5 };

  // Atoms
  const [design, setDesign] = useRecoilState(designAtom);
  const [color, setColor] = useRecoilState(colorAtom);
  const [secondaryColor, setSecondaryColor] =
    useRecoilState(secondaryColorAtom);
  const [iconSize, setIconSize] = useRecoilState(iconSizeAtom);
  const [strokeSize, setStrokeSize] = useRecoilState(strokeSizeAtom);

  const [frameDesign, setFrameDesign] = useRecoilState(frameDesignAtom);
  const [frameColor, setFrameColor] = useRecoilState(frameColorAtom);
  const [frameBorderColor, setFrameBorderColor] =
    useRecoilState(frameBorderColorAtom);
  const [frameBorderRadius, setFrameBorderRadius] = useRecoilState(
    frameBorderRadiusAtom
  );

  return (
    <div className="filterbar">
      <div className="form-field">
        <label htmlFor="">Icon Styles</label>
        <div className="radio">
          {availableDesigns &&
            availableDesigns.map((Item) => (
              <Fragment key={Item.name}>
                <input
                  type="radio"
                  value={Item.name}
                  className="radio__input"
                  name="styles"
                  id={Item.name}
                  onChange={() => setDesign(Item.name)}
                  checked={design === Item.name}
                />
                <label className="radio__label" htmlFor={Item.name}>
                  {Item.name}
                </label>
              </Fragment>
            ))}
        </div>
      </div>

      <div className="swatch">
        <label htmlFor="">Color</label>
        <div
          className="color-swatch"
          onClick={() => setIcoPrimary((icoPrimary) => !icoPrimary)}
        >
          <div className="color" style={{ backgroundColor: color }} />
        </div>
        {icoPrimary && (
          <div className="popover">
            <div
              className="popover-cover"
              onClick={() => setIcoPrimary(false)}
            />
            <ChromePicker
              disableAlpha
              color={color}
              onChange={(color) => setColor(color.hex)}
            />
          </div>
        )}
      </div>

      {design && design === "color" && (
        <>
          <div className="swatch">
            <label htmlFor="">Secondary Color</label>
            <div
              className="color-swatch"
              onClick={() => setIcoSecondary((icoSecondary) => !icoSecondary)}
            >
              <div
                className="color"
                style={{ backgroundColor: secondaryColor }}
              />
            </div>
            {icoSecondary && (
              <div className="popover">
                <div
                  className="popover-cover"
                  onClick={() => setIcoSecondary(false)}
                />
                <ChromePicker
                  color={secondaryColor}
                  onChange={(color) => setSecondaryColor(color.hex)}
                />
              </div>
            )}
          </div>
        </>
      )}

      <div className="form-field">
        <label htmlFor="">
          Icon Size <span>(Sizes in "px")</span>
        </label>
        <div className="radio">
          {availableIconSizes &&
            availableIconSizes.map((size) => (
              <Fragment key={"size_" + size}>
                <input
                  type="radio"
                  value={size}
                  className="radio__input"
                  name="size"
                  id={"size_" + size}
                  onChange={() => setIconSize(size)}
                  checked={iconSize === size}
                />
                <label className="radio__label" htmlFor={"size_" + size}>
                  {size}
                </label>
              </Fragment>
            ))}
        </div>
      </div>

      <div className="form-field">
        <label htmlFor="">
          Stroke Size <span>(Sizes in "px")</span>
        </label>
        <div className="radio">
          {strokes &&
            Object.keys(strokes).map((name) => (
              <Fragment key={name}>
                <input
                  type="radio"
                  value={strokes[name]}
                  className="radio__input"
                  name="stroke"
                  id={"stroke_" + name}
                  onChange={() => setStrokeSize(strokes[name])}
                  checked={strokeSize === strokes[name]}
                />
                <label className="radio__label" htmlFor={"stroke_" + name}>
                  {name}
                </label>
              </Fragment>
            ))}
        </div>
      </div>

      <div className="form-field">
        <label htmlFor="">Background Frame</label>
        <div className="radio">
          <input
            type="radio"
            value="none"
            className="radio__input"
            name="frame"
            id="none"
            onChange={() => setFrameDesign("none")}
            checked={frameDesign === "none"}
          />
          <label className="radio__label" htmlFor="none">
            None
          </label>

          <input
            type="radio"
            value="square"
            className="radio__input"
            name="frame"
            id="square"
            onChange={() => setFrameDesign("square")}
            checked={frameDesign === "square"}
          />
          <label className="radio__label" htmlFor="square">
            Square
          </label>
        </div>
      </div>
      {frameDesign !== "none" ? (
        <>
          <div className="swatch">
            <label htmlFor="">Frame Border</label>
            <div
              className="color-swatch"
              onClick={() =>
                setIcoFrameborder((icoFrameborder) => !icoFrameborder)
              }
            >
              <div
                className="color"
                style={{ backgroundColor: frameBorderColor }}
              />
            </div>
            {icoFrameborder ? (
              <div className="popover">
                <div
                  className="popover-cover"
                  onClick={() => setIcoFrameborder(false)}
                />
                <ChromePicker
                  color={frameBorderColor}
                  onChange={(color) => setFrameBorderColor(color.hex)}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="swatch">
            <label htmlFor="">Frame Background</label>
            <div
              className="color-swatch"
              onClick={() =>
                setIcoFrameBackground(
                  (icoFrameBackground) => !icoFrameBackground
                )
              }
            >
              <div className="color" style={{ backgroundColor: frameColor }} />
            </div>
            {icoFrameBackground ? (
              <div className="popover">
                <div
                  className="popover-cover"
                  onClick={() => setIcoFrameBackground(false)}
                />
                <ChromePicker
                  color={frameColor}
                  onChange={(color) => setFrameColor(color.hex)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Filter;
