import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { SVGBook } from "@svgbook/react-icons";

const Topbar = styled.header`
  border-bottom: 1px solid #c5c5c5;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
`;
const Nav = styled.nav`
  > a {
    font-weight: 500;
    margin: 0 10px;
    position: relative;
  }
  > a:hover {
    color: #673ab7;
  }
  > a > span {
    position: absolute;
    background-color: #673ab7;
    color: #fff;
    padding: 1px 5px;
    border-radius: 3px;
    font-size: 0.6rem;
    top: -12px;
    right: -25px;
  }
`;
const Logo = styled.div`
  margin-right: 2rem;
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .svgBook-name {
    font-size: 25px;
    text-transform: uppercase;
  }
`;

class Header extends Component {
  render() {
    return (
      <Topbar>
        <Wrapper>
          <Logo>
            <Link to="/" className="logo">
              <SVGBook size="55px" />
              <span className="svgBook-name">
                <strong>SVG</strong>Book
              </span>
            </Link>
          </Logo>
          <Nav>
            <NavLink to="/icons" className="nav-link">
              Icons
            </NavLink>
            <NavLink to="/illustrations" className="nav-link">
              Illustrations <span>SOON</span>
            </NavLink>
          </Nav>
        </Wrapper>
      </Topbar>
    );
  }
}

export default Header;
