import React, { useState, useEffect } from "react";
import IconsFilter from "../common/IconsFilter";
import IconsDownloader from "../common/IconsDownloader";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";
import AllIcons from "../config/IconObject";
import Fuse from "fuse.js";
import { googleAnalyticsActions } from "../config/GoogleAnalytics";

import {
  colorAtom,
  secondaryColorAtom,
  designAtom,
  iconSizeAtom,
  strokeSizeAtom,
  selectedIconAtom,
  frameDesignAtom,
  frameColorAtom,
  frameBorderColorAtom,
} from "../state/atoms";

function IconsPage() {
  const design = useRecoilValue(designAtom);
  const color = useRecoilValue(colorAtom);
  const secondaryColor = useRecoilValue(secondaryColorAtom);
  const size = useRecoilValue(iconSizeAtom);
  const strokeSize = useRecoilValue(strokeSizeAtom);

  const [selectedIcon, setSelectedIcon] = useRecoilState(selectedIconAtom);
  const [search, setSearch] = useState("");
  const [filteredIcons, setFilteredIcons] = useState(AllIcons);

  const frameDesign = useRecoilValue(frameDesignAtom);
  const frameColor = useRecoilValue(frameColorAtom);
  const frameStroke = useRecoilValue(frameBorderColorAtom);

  useEffect(() => {
    googleAnalyticsActions.initGoogleAnalytics("UA-207442904-1");
    const fuseConfig = {
      minMatchCharLength: 1,
      threshold: 0.3,
      keys: ["name", "tags"],
    };
    const fuse = new Fuse(AllIcons, fuseConfig);
    if (search !== "") {
      setFilteredIcons(stripFuse(fuse.search(search)));
    } else {
      setFilteredIcons(AllIcons);
    }
  }, [search]);

  const stripFuse = (list) => {
    return list.map((item) => item.item);
  };

  const _handleIconClick = (icon) => {
    setSelectedIcon(icon);
  };

  const _handleSearchChange = (value) => {
    setSearch(value);
  };

  const renderIcon = ({ name, component: IconComponent }) => {
    const frameOptions =
      frameDesign !== "none"
        ? { frameColor: frameColor, frameBorderColor: frameStroke }
        : {};

    return (
      <div
        key={name}
        className={cn({
          icon: true,
          selected: name === selectedIcon,
        })}
        onClick={() => _handleIconClick(name)}
      >
        <IconComponent
          size={size}
          design={design}
          color={color}
          strokeWidth={strokeSize}
          secondaryColor={secondaryColor}
          {...frameOptions}
          // TODO Make this dynamic
          frameBorderRadius={1}
        />
        <span>{name}</span>
      </div>
    );
  };

  return (
    <div className="main-wraper">
      <IconsFilter />
      <div className="content">
        <div className="p-3">
          <div className="searchbar-head">
            <div className="searchbar">
              <input
                className="form-control"
                style={{ flex: 1 }}
                placeholder="Search..."
                type="text"
                value={search}
                onChange={(e) => _handleSearchChange(e.target.value)}
              />
            </div>
          </div>

          <section>
            <div className="icon-grid">
              {filteredIcons &&
                filteredIcons.map((icon, index) => renderIcon(icon, index))}
            </div>
          </section>
        </div>
      </div>
      <IconsDownloader />
    </div>
  );
}
export default IconsPage;
