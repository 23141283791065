import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  min-height: calc(100vh - 66px);
  text-align: center;
`;
export const Heading = styled.h1`
  font-size: 8vw;
`;
