import React, { useEffect } from "react";
import Header from "./common/Header";
import { Switch, Route, Redirect } from "react-router-dom";

// import ReactGA from 'react-ga';
import { googleAnalyticsActions } from "./config/GoogleAnalytics";

import NotFound from "./component/notFound";
import Home from "./pages/Home";
import Icons from "./pages/Icons";
import Illustrations from "./pages/Illustrations";

const App = () => {
  useEffect(() => {
    googleAnalyticsActions.initGoogleAnalytics("UA-207442904-1");
  }, []);
  return (
    <React.Fragment>
      <Header />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/icons" exact component={Icons} />
        <Route path="/illustrations" exact component={Illustrations} />
        <Route path="/not-found" component={NotFound} />
        <Redirect to="/not-found" />
      </Switch>
    </React.Fragment>
  );
};

export default App;
