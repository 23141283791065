import React, { useEffect } from "react";
import { Wrapper, Heading } from "./Illustrations.styled";
import { googleAnalyticsActions } from "../config/GoogleAnalytics";
import { Helmet } from "react-helmet";

const Illustrations = () => {
  useEffect(() => {
    googleAnalyticsActions.initGoogleAnalytics("UA-207442904-1");
  }, []);
  return (
    <>
      <Helmet>
        <title>Download Free premium svg illustrations and vectors.</title>
      </Helmet>
      <Wrapper>
        <Heading>We are working on illustrations.</Heading>
      </Wrapper>
    </>
  );
};

export default Illustrations;
