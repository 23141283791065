import { atom } from "recoil";

export const designAtom = atom({
  key: "designAtom",
  default: "color",
});

export const colorAtom = atom({
  key: "colorAtom",
  default: "#000000",
});

export const secondaryColorAtom = atom({
  key: "secondaryColorAtom",
  default: "#ffd43b",
});

export const iconSizeAtom = atom({
  key: "iconSizeAtom",
  default: 72,
});

export const strokeSizeAtom = atom({
  key: "strokeSizeAtom",
  default: 1.0,
});

export const selectedIconAtom = atom({
  key: "selectedIcon",
  default: null,
});

export const frameDesignAtom = atom({
  key: "frameDesignAtom",
  default: "none",
});

export const frameColorAtom = atom({
  key: "frameColorAtom",
  default: "#e8e8e8",
});

export const frameBorderColorAtom = atom({
  key: "frameStrokeAtom",
  default: "#868686",
});

export const frameBorderRadiusAtom = atom({
  key: "frameBorderRadiusAtom",
  default: 5,
});
