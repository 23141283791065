import styled from "styled-components";

export const Grid = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: column;
  columns: 2;
  gap: 2rem;
  padding: 0 15px;
  > * {
    break-inside: avoid;
    margin-bottom: 2rem;
  }
  @media (max-width: 750px) {
    columns: 1;
  }
`;
export const Button = styled.a`
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.1px;
  display: inline-block;
  padding: 1rem 5rem;
  border-radius: 2rem;
  background: #e91e63;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-top: 32px;
  &:hover {
    color: #fff;
    background-color: #ec407a;
  }
`;
export const Box = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
  border-radius: 1rem;
`;
export const Hero = styled(Box)`
  text-align: center;
  margin: 1rem auto;
  padding: 90px 30px;
  a {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.1px;
    display: inline-block;
    padding: 1rem 5rem;
    border-radius: 2rem;
    background: #e91e63;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-top: 32px;
    &:hover {
      color: #fff;
      background-color: #ec407a;
    }
  }
`;
export const H1 = styled.h1`
  font-size: 6vw;
  line-height: 1;
  margin-bottom: 0.75rem;
`;
